import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import {
  PageNav,
  AltPanels,
} from 'layouts';
import {
  Banner,
  Breadcrumbs,
} from 'components';
import { PAGES, BASE_URL } from 'config';

const BREADCRUMBS = [
  {
    name: PAGES.ABOUT.TITLE,
    absolute_slug: PAGES.ABOUT_BRAND.PATH,
  },
  {
    name: PAGES.ABOUT_BRAND.TITLE,
    absolute_slug: PAGES.ABOUT_BRAND.PATH,
  },
];

const AboutBrandPage = () => (
  <Layout page="about/brand">

    <Helmet
      title="New Zealand Indigenous Culture | Māori Values | Haka Tours"
      meta={[
        {
          name: 'description', content: 'Our Māori values & culture is incredibly important to us, which is why we weave Māori culture from New Zealand & adventure into all our small group tours.',
        },
        { name: 'og:title', content: 'New Zealand Indigenous Culture | Māori Values | Haka Tours' },
        {
          name: 'og:description', content: 'Our Māori values & culture is incredibly important to us, which is why we weave Māori culture from New Zealand & adventure into all our small group tours.',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/about-us/our-culture/` }]}
    />

    <Banner backgroundImage="/images/our-culture-banner.jpg?fit=crop&auto=format" modifier="bg-position-30">
      <h1 className="c-heading c-heading--h0">Our New Zealand Indigenous Culture</h1>
    </Banner>
    <PageNav pages={[PAGES.ABOUT_TRIPS, PAGES.ABOUT_STORY, PAGES.ABOUT_BRAND, PAGES.ABOUT_PEOPLE, PAGES.ABOUT_AWARDS, PAGES.ABOUT_FAQS, PAGES.ABOUT_REVIEWS]} />
    <section className="l-section">
      <div className="l-container">
        <Breadcrumbs list={BREADCRUMBS} />
      </div>
      <div className="l-container l-container--small u-text--center">
        <h2 className="c-heading c-heading--h2">FOR HAKA, CULTURE IS EVERYTHING</h2>
        <p>
          Proud of our New Zealand roots, showcasing our indigenous Māori culture is incredibly important to us, which is why we weave equal measures of culture and adventure into all our small group tours.
        </p>
        <p>
          Whether you’re carving a greenstone pendant, paddling a waka (Māori canoe), eating a hāngī or learning the myths and legends that are a cornerstone of our Māori culture in New Zealand,
          helping you make that cultural connection is vital to enhancing your New Zealand experience.
        </p>
      </div>
    </section>
    <AltPanels items={[
      {
        title: 'Why are we called Haka?',
        content: `<p>Our purpose is to foster deeper connections between our manuhiri (guests), our land and our culture through exceptional travel experiences,
        and we wanted a name that was truly reflective of this purpose and the cultural education that underpins all our tours. But calling ourselves Haka Tours means we have a huge responsibility to live up to the mana that this name deserves,
        by ensuring we operate a world-class tourism business.</p>`,
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: '/images/our-culture-why-are-we-called-haka.jpg?w=652&h=418&fit=crop&auto=format',
          align: '',
        },
      },
      {
        title: 'The meaning behind the word Haka',
        content: '<p>Historically, a haka peruperu (war dance with weapons) was performed by warriors before a battle to showcase strength and prowess and intimidate the enemy.'
          + ' Today, while the haka is internationally best known for its renditions on the rugby field, it also constitutes an integral part of the pōwhiri, or welcoming ceremony for distinguished guests.</p>'
          + '<p>These two photos are from the powhiri and spiritual blessing for our Haka Lodge in Taupō where a haka was performed, followed by a hongi.</p>',
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://static-hakatours.imgix.net/our-culture-02b.jpg?w=652&h=418&fit=crop&auto=format',
          align: '',
        },
      },
      {
        title: 'The right to use the word Haka',
        content: '<p>We went through a lengthy intellectual property process and submitted to the Māori Advisory Committee and were genuinely honoured to be granted the right to use the name Haka Tours'
          + ' But that is just the beginning and we have a <a href="/about-us/our-people" target="_blank">Māori Cultural Advisor</a>, Eru, '
          + 'whose purpose is to develop our teams cultural capability and knowledge, to ensure we honour our name by being an example of cultural best practice.</p>',
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://static-hakatours.imgix.net/our-culture-03b.jpg?w=652&h=418&fit=crop&auto=format',
          align: '',
        },
      },
      {
        title: 'Incorporating cultural protocol into our tours',
        content: '<p>When you join a Haka Tour, you’ll be introduced to Māori culture from the outset with a'
          + ' <a href="https://hakatours.com/about-us/our-haka-welcome-haka-and-farewell/" target="_blank">traditional Māori welcome or Mihi, and a Haka haka</a>,'
          + ' followed by a Poroporoaki (farewell) at the end of the tour.'
          + ' We have an innovative programme that <a href="https://hakatours.com/about-us/our-staff-get-paid-to-learn-maori/" target="_blank">rewards our staff for learning Te Reo</a> (the Māori Language)'
          + ' and we also offer a <a href="https://hakatours.com/about-us/maori-tour-manager-scholarships" target="_blank">Māori Tour Manager Scholarship</a> to encourage more Māori to consider a career in tourism.</p>',
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://static-hakatours.imgix.net/our-culture-04b.jpg?w=652&h=418&fit=crop&auto=format',
          align: '',
        },
      },
      {
        title: 'We operate in accordance with Maori values',
        content: '<p>Haka Tourism Group is committed to respectfully incorporating <a href="/about-us/our-values" target="_blank">Māori principles and values</a> into our business practices.'
          + ' We’re guided by the three core Māori values of <b>Whanaungatanga</b> (<i>relationships and feeling a sense of belonging</i>),'
          + ' <b>Kaitiakitanga</b> (<i>guardianship and the connection between people and the natural world</i>) and <b>Manaakitanga</b> (<i>hospitality and the act of caring for a person’s mana</i>).</p>',
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: '/images/our-culture-we-operate-in-accordance-.jpg?w=652&h=418&fit=crop&auto=format',
          align: '',
        },
      },
    ]}
    />

    <section className="l-section">
      <div className="l-container l-container--small u-text--center">
        <p>&nbsp;</p>
      </div>
    </section>

    {/* <BlogList */}
    {/* title="The Latest Haka News" */}
    {/* list={[1, 2]} */}
    {/* /> */}

  </Layout>
);

export default AboutBrandPage;
